import "./../scss/main.scss";
import * as bootstrap from "bootstrap";
import leaflet from "leaflet";
import PhotoSwipeLightbox from 'photoswipe/lightbox';

(function(){
    let contactMapElement = document.getElementById("contact-map");
    const goUpArrowElement = document.getElementById("goToTop");
    const pageHeader = document.querySelector(".header");
    const certificateLink = document.querySelector("#open-certificate-link");
    const certificateGalleryElement = document.querySelector("#certificates-gallery");
    
    function generateMap(){
        contactMapElement = leaflet.map("contact-map").setView([Number(mapData.latitude), Number(mapData.longitude)], 15);
    
        leaflet.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19, 
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(contactMapElement);
    
        let marker = leaflet.marker([Number(mapData.latitude), Number(mapData.longitude)]).addTo(contactMapElement);
    }
    
    if(contactMapElement){
        generateMap();
    }
    
    goUpArrowElement.addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    // Fixed header
    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;

        if(currentScroll > 122){
            pageHeader.classList.add("header-scroll");
        } else{
            pageHeader.classList.remove("header-scroll");
        }
    });

    // Photoswipe
    const certificateGallery = new PhotoSwipeLightbox({
        gallery: "#certificates-gallery",
        children: 'a',
        pswpModule: () => import('photoswipe')
    });
  
    certificateGallery.init();

    if(certificateLink){
        certificateLink.addEventListener("click", (e) => {
            e.preventDefault();
    
            certificateGallery.loadAndOpen(0, {
                gallery: certificateGalleryElement
            });
        });
    }
})();

